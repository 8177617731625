import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import './PredictiveScoring.scss';
import LeadEstimates from './channels/LeadScoring';
import OppurtunityScoring from './channels/OppurtunityScoring';
import AccountScoring from './channels/AccountScoring';
import SideBar from '../../components/SideAndTopBar/SideBar';
import headerService from '../../services/header.service';
import { useDateRange } from '../../components/DateTimePicker/DateRangeContext';
import HumanVSAI from './channels/HumanVSAI';
import useApiService from '../../services/api.service';
import useToast from '../../components/Toast/hooks/useToast';
import PredictiveScoringEndpoints from './PredictiveScoringEndpoints';
import AIInsights from './channels/AIInsights';
import { FilterProvider } from './channels/AIINsights/FIltersContext';
import { useTabType } from '../../components/SideAndTopBar/TabsContext';

const PredictiveScoring = (): JSX.Element => {
	const { level } = useTabType();
	const { dateRange } = useDateRange();
	return (
		<>
			<FilterProvider>
				<div
					className='Advertisement_channel_effect'
					style={{ width: '100%', padding: '20px' }}
				>
					<div
						className='ppc__scrollArea'
						style={{
							marginTop: '64px',
						}}
					>
						{level === 0 && <LeadEstimates dateRange={dateRange} />}
						{level === 1 && <OppurtunityScoring dateRange={dateRange} />}
						{level === 2 && <AccountScoring dateRange={dateRange} />}
						{level === 3 && <HumanVSAI dateRange={dateRange} />}
						{level === 4 && <AIInsights dateRange={dateRange} />}
					</div>
				</div>
			</FilterProvider>
		</>
	);
};

export default PredictiveScoring;
