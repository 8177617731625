import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DiggiChat from '../../assets/Images/Sidebar/DiggiChat.svg';
import DiggiChatWhite from '../../assets/Images/Sidebar/DiggiChatWhite.svg';
import diggGrowthLogoDark from '../../assets/Images/digGrowthLogo.svg';
import diggGrowthLogoLight from '../../assets/Images/digGrowthLogoLight.svg';
import PredictiveScoring from '../../assets/Images/Sidebar/PredictiveScoring.svg';
import PredictiveScoringWhite from '../../assets/Images/Sidebar/PredictiveScoringWhite.svg';
import { styled, useTheme } from '@mui/material/styles';
import useLocalStorage from '../../utils/localStorage';
import RightNavigationIcons from './RightNavigation/RightNavigation';
import AIChatBotQuestions from '../../containers/AIChatBot/AIChatBotQuestions/AIChatBotQuestions';
import { useNavigate } from 'react-router';
import MMM from '../../assets/Images/Sidebar/MMM.svg';
import DataEnrichment from '../../assets/Images/Sidebar/DataEnrichment.svg';
import DataEnrichmentWhite from '../../assets/Images/Sidebar/DataEnrichmentWhite.svg';
import ProfileLogo from '../../containers/AIChatBot/ProfileLogo';
import { useTabType } from './TabsContext';
import {
	AppBar,
	DateRangePaths,
	DrawerHeader,
	getInitialSelectedItem,
	MMMItems,
	predictiveScoringItems,
	secondSidebarPaths,
} from './SidebarData';
import Divider from '@mui/material/Divider';
import { useMediaQuery } from '@mui/material';
import { height } from '@mui/system';

export default function SideBar(props: any) {
	const theme = useTheme();
	const { level, setLevel, setMMMLevel } = useTabType();
	const navigate = useNavigate();
	const isPathInArray = (pathArray: string[], currentPath: string) => {
		return pathArray.includes(currentPath);
	};
	const showDateRange = isPathInArray(DateRangePaths, window.location.pathname);
	const showSecondSidebar = isPathInArray(
		secondSidebarPaths,
		window.location.pathname
	);
	const [selectedMMMItem, setSelectedMMMItem] = React.useState<string | null>(
		null
	);
	const [selectedScoringItem, setSelectedScoringItem] = React.useState<
		string | null
	>(getInitialSelectedItem(level));
	const isMediumScreen = useMediaQuery(theme.breakpoints.between(1200, 1400));
	const userData: any = useLocalStorage.getItem('userData');
	const name: any = userData?.name;
	const [selectedItem, setSelectedItem] = React.useState<string>();
	const email: any = userData?.email;
	const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
	const [isSecondDrawerOpen, setIsSecondDrawerOpen] = React.useState(false);
	const isChatbot = window.location.pathname == '/insights/chatbot';
	const isScoring = window.location.pathname == '/insights/predictive-scoring';
	const isMMM = window.location.pathname == '/insights/Media-Mix-Modelling';

	const handleItemClick = () => {
		if (isChatbot) {
			setSelectedItem('Diggi-GPT'); // Set specific item for chatbot
		} else if (isScoring) {
			setSelectedItem('Predictive Scoring'); // Set the clicked item for predictive scoring
		} else if (isMMM) {
			setSelectedItem(''); // Set the clicked item for MMM
		}
	};

	const handleSecondDrawerToggle = () => {
		const newDrawerState = !isSecondDrawerOpen;
		setIsSecondDrawerOpen(newDrawerState);
		props?.onSecondDrawerToggle(newDrawerState); // Trigger callback with the new state of the second drawer
	};

	const isClient = useLocalStorage.getItem('isClient');
	const Data = useLocalStorage.getItem('Client_data');
	const DSLogo = Data?.DSLogo;
	const LSLogo = Data?.LSLogo;

	const menuItems = [
		{
			label: 'Diggi-GPT',
			icon: (
				<img src={DiggiChat} alt='Diggi GPT' style={{ fontSize: 'inherit' }} />
			),
			iconSelected: (
				<img src={DiggiChatWhite} alt='Diggi GPT' style={{ fontSize: 'inherit' }} />
			),
			onClick: () => {
				setSelectedItem('Diggi-GPT');
				navigate('insights/chatbot');
				setIsSecondDrawerOpen(true);
				props?.onSecondDrawerToggle(true);
			},
		},
		{
			label: 'Predictive Scoring',
			icon: (
				<img
					src={PredictiveScoring}
					alt='Predictive Scoring'
					style={{ fontSize: 'inherit' }}
				/>
			),
			iconSelected: (
				<img
					src={PredictiveScoringWhite}
					alt='Predictive Scoring'
					style={{ fontSize: 'inherit' }}
				/>
			),
			onClick: () => {
				setSelectedItem('Predictive Scoring');
				navigate('insights/predictive-scoring');
				setIsSecondDrawerOpen(true);
				props?.onSecondDrawerToggle(true);
			},
		},
		// {
		// 	label: 'MMM',
		// 	icon: <img src={MMM} alt='MMM' style={{ fontSize: 'inherit' }} />,
		// 	onClick: () => {
		// 		navigate('insights/Media-Mix-Modelling');
		// 		setIsSecondDrawerOpen(true);
		// 		props?.onSecondDrawerToggle(true);
		// 	},
		// },
		{
			label: 'Data Enrichment',
			icon: (
				<img
					src={DataEnrichment}
					alt='Data Enrichment'
					style={{ fontSize: 'inherit' }}
				/>
			),
			iconSelected: (
				<img
					src={DataEnrichmentWhite}
					alt='Data Enrichment'
					style={{ fontSize: 'inherit' }}
				/>
			),
			onClick: () => {
				setSelectedItem('Data Enrichment');
				navigate('insights/Data-Enrichment');
				setIsSecondDrawerOpen(false);
				props?.onSecondDrawerToggle(false);
			},
		},
	];

	React.useEffect(() => {
		if (!showSecondSidebar) {
			setIsSecondDrawerOpen(false);
			props?.onSecondDrawerToggle(false);
		}
	}, [window.location.pathname]);

	React.useEffect(() => {
		handleItemClick();
	}, []);

	return (
		<Box sx={{ display: 'flex', boxShadow: 'none !important' }}>
			<AppBar
				sx={{
					boxShadow: 'none !important',
					border: 'none !important',
					borderRadius: '0px !important',
				}}
				style={{
					backgroundColor:
						theme.palette.mode === 'dark'
							? 'black'
							: isChatbot
							? 'transparent'
							: '#f4f7fc',
				}}
				position='fixed'
				open={isSidebarOpen}
			>
				<Toolbar
					style={{
						marginLeft: isSidebarOpen ? '0px' : '60px',
						display: 'flex',
						justifyContent: 'space-between',
						boxShadow: 'none !important',
						maxHeight:"71px",
						backgroundColor:
							theme.palette.mode === 'dark'
								? isChatbot
									? 'black'
									: '#1A1A1A'
								: isChatbot
								? 'transparent'
								: '#f4f7fc',
					}}
				>
					<div style={{ display: 'flex' }}>
						<div style={{ zIndex: 1201 }}>
							{isClient ? (
								<img
									src={theme.palette.mode === 'dark' ? DSLogo : LSLogo}
									alt='Logo'
									style={{ maxHeight: '85px' }}
								/>
							) : (
								<img
									src={
										theme.palette.mode === 'dark'
											? diggGrowthLogoDark
											: diggGrowthLogoLight
									}
									style={{ maxHeight: '85px' }}
									alt='Company Logo'
								/>
							)}
						</div>
					</div>
					{showDateRange && <RightNavigationIcons />}
				</Toolbar>
			</AppBar>

			{/* First Drawer */}
			<Drawer
				sx={{
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: '80px',
						backgroundColor:
							theme.palette.mode === 'dark' ? '#1A1A1A' : '#f4f7fc',
						borderRight: 'none',
						borderRadius: '0px !important',
						display: 'flex',
						flexDirection: 'column', // Use flexbox to align items vertically
					},
				}}
				variant='persistent'
				anchor='left'
				open={true}
			>
				<DrawerHeader sx={{ justifyContent: 'center' }}>
					<IconButton
						onClick={showSecondSidebar ? handleSecondDrawerToggle : undefined}
					>
						<MenuIcon sx={{ fontSize: '36px' }} />
					</IconButton>
				</DrawerHeader>

				<List sx={{ flexGrow: 1 }}>
					{' '}
					{menuItems.map((item, index) => (
						<ListItem
							button
							key={index}
							sx={{
								justifyContent: 'center',
								padding: '10px 0',
								flexDirection: 'column',
								background:
									selectedItem === item.label
										? 'linear-gradient(63.53deg, #9149ff 16.62%, #e600ff 83.38%), linear-gradient(0deg, rgba(145, 73, 255, 0.01), rgba(145, 73, 255, 0.01))' // Color for selected item
										: 'transparent',
							}}
							onClick={item.onClick} // Attach click handler dynamically
						>
							<ListItemIcon
								sx={{ minWidth: 0, fontSize: '36px', justifyContent: 'center' }}
							>
								{selectedItem === item.label ? item.iconSelected : item.icon}
							</ListItemIcon>
							<ListItemText
								primary={item.label}
								primaryTypographyProps={{
									fontSize: '12px',
									fontWeight: 'bold',
									textAlign: 'center',
								}}
								sx={{ marginTop: '4px' }} // Add margin for spacing
							/>
						</ListItem>
					))}
				</List>
				<Divider />
				<div
					style={{
						marginTop: 'auto',
						display: 'flex',
						justifyContent: 'center',
						padding: '20px',
					}}
				>
					<ProfileLogo />
				</div>
			</Drawer>
			<Drawer
				sx={{
					'& .MuiDrawer-paper': {
						width: isSecondDrawerOpen
							? isMediumScreen
								? '200px'
								: '300px'
							: '0px',
						marginLeft: '80px',
						backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
						borderRadius: '0px !important',
						borderRight: '0px !important',
						transform: 'none !important',
						transition: 'none !important',
						zIndex: 1,
						display: 'flex',
						flexDirection: 'column',
					},
				}}
				variant='persistent'
				anchor='left'
				open={isSecondDrawerOpen}
				onClose={handleSecondDrawerToggle}
			>
				<DrawerHeader></DrawerHeader>
				<List>
					{isChatbot && <AIChatBotQuestions />}
					{isScoring &&
						predictiveScoringItems.map((item, index) => (
							<>
								<ListItem
									button
									key={index}
									onClick={() => {
										setLevel(item.onClick);
										setSelectedScoringItem(item.label); // Update selected scoring item
									}}
									sx={{
										display: 'flex',
										alignItems: 'center',
										padding: '12px 16px',
										background:
											selectedScoringItem === item.label
												? 'linear-gradient(63.53deg, #9149ff 16.62%, #e600ff 83.38%), linear-gradient(0deg, rgba(145, 73, 255, 0.01), rgba(145, 73, 255, 0.01))'
												: 'transparent',
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: '40px',
											color: 'inherit',
											justifyContent: 'center',
											fontSize: '25px',
										}}
									>
										{selectedScoringItem === item.label ? item.iconSelected : item.icon}
									</ListItemIcon>
									<ListItemText
										primary={item.label}
										primaryTypographyProps={{
											fontSize: '16px',
											fontWeight: 500,
										}}
										sx={{
											marginLeft: '16px',
										}}
									/>
								</ListItem>
								<Divider />
							</>
						))}

					{isMMM &&
						MMMItems.map((item, index) => (
							<>
								<ListItem
									button
									key={index}
									onClick={() => {
										setMMMLevel(item.level);
										setSelectedMMMItem(item.label); // Update selected MMM item
									}}
									sx={{
										display: 'flex',
										alignItems: 'center',
										padding: '12px 16px',
										background:
											selectedMMMItem === item.label
												? 'linear-gradient(63.53deg, #9149ff 16.62%, #e600ff 83.38%), linear-gradient(0deg, rgba(145, 73, 255, 0.01), rgba(145, 73, 255, 0.01))'
												: 'transparent',
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: '40px',
											color: 'inherit',
											justifyContent: 'center',
											fontSize: '25px',
										}}
									>
										{item.icon}
									</ListItemIcon>
									<ListItemText
										primary={item.label}
										primaryTypographyProps={{
											fontSize: '16px',
											fontWeight: 500,
										}}
										sx={{
											marginLeft: '16px',
										}}
									/>
								</ListItem>
								<Divider />
							</>
						))}
				</List>

				<div
					style={{
						marginTop: 'auto',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						padding: '12px 24px',
						alignItems: 'center',
					}}
				>
					<div>{` ${name} `}</div>
					<div>{`${email}`}</div>
				</div>
			</Drawer>
		</Box>
	);
}
