/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import DiggLogo from '../../../assets/Images/Sidebar/DiggLogo.svg';
import ProfileLogo from '../../../containers/AIChatBot/ProfileLogo';
import './RightNavigation.scss';
import useLocalStorage from '../../../utils/localStorage';
import { DateTimeFormatter } from '../../../utils/DateFormatter';
import ReactDateTimePicker from '../../DateTimePicker/ReactDateTimePicker';
import { useDateRange } from '../../DateTimePicker/DateRangeContext'; 
import ToolTipOnText from '../../Tooltip/Tooltip';
const RightNavigationIcons = () => {
    const { dateRange, setDateRange} = useDateRange(); 
	const currentEnv = location.href;
	const beta = currentEnv.includes('beta');
	const app = currentEnv.includes('app');
	const localDateRange: any = useLocalStorage.getItem('dateRange');
	const comparisonDateRange: any = useLocalStorage.getItem('compdateRange');
	const [showComparison, setShowComparison]: any = useState(
		useLocalStorage.getItem('date_comparison')
	);
	
	const isChatbot = window.location.pathname == '/insights/chatbot';
	const onChangeDateFilter = (event: any, selectedLabel: any) => {
		let dateRange = {
            start_date: DateTimeFormatter.getDateInFormat(event.selection1.startDate),
            end_date: DateTimeFormatter.getDateInFormat(event.selection1.endDate),
			pre_start_date: DateTimeFormatter.getDateInFormat(
				event.selection2.startDate
			),
            pre_end_date: DateTimeFormatter.getDateInFormat(event.selection2.endDate),
        };
        setDateRange(dateRange); // Update the dateRange in the context
    };

	const onCompareChange = (event: any) => {
		setShowComparison(useLocalStorage.getItem('date_comparison'));
	};

    const url = window.location.origin;

	const SwitchToDigg = () => {
		let currentUrl = url
        if(location.hostname == 'ai.phlogic.com') {
         currentUrl = 'https://app.phlogic.com/login';
        }
        else {
         currentUrl = url.replace('.ai', '.com') + '/login';
        }
		
		if (process.env.REACT_APP_KEY_CLOAK_URL) {
			const keycloakUrl = process.env.REACT_APP_KEY_CLOAK_URL;
			const realmRedirect = process.env.REACT_APP_REALM_REDIRECT;
			const clientId = process.env.REACT_APP_KEY_CLOAK_CLIENT_ID;
	
			const fullUrl = `${keycloakUrl}${realmRedirect}/protocol/openid-connect/auth?response_type=code&client_id=${clientId}&redirect_uri=${currentUrl}`;
			
			// Open the URL in a new tab
			window.open(fullUrl, '_blank');
		}
	};
    return (
        <div className='rightNavigation'>
            <ToolTipOnText title="Switch to Diggrowth">
            <img
				onClick={() => {
					SwitchToDigg();
				}}
                style={{
                    cursor: 'pointer',
                }}
                height={"40px"}
                src={DiggLogo}
                alt=''
            />
            </ToolTipOnText>
			{!isChatbot ? (
            <ReactDateTimePicker
                widthCheckOnFormat
                onChange={onChangeDateFilter}
					onCompareChange={(evt: any) => onCompareChange(evt)}
                start_date={dateRange.start_date}
                end_date={dateRange.end_date}
                pre_start_date={dateRange.pre_start_date}
                pre_end_date={dateRange.pre_end_date}
                showComparison={true}
                isMultipleRanges
                defaultMonths={1}
            />
			) : null}
      
        </div>
    );
};

export default RightNavigationIcons;
